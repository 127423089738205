.project_filters {
  display: flex;
  align-items: center;
  column-gap: 1.875rem;
  margin-bottom: 2.5rem;
}
.project_item {
  cursor: pointer;
  font-weight: 500px;
  transition: 0.3s;
}

.project_item:hover {
  color: rgb(7, 177, 244);
}
.project_container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}
.project_container {
  position: relative;
}

.project_category {
  color: rgb(255, 255, 255);
  background-color: rgb(235, 185, 4);
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 1.2rem;
  font-size: medium;
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transform: translateX(-20px);
  transition: 0.3s;
}
.project_title {
  color: white;
  background-color: #0096df;
  border-radius: 12px;
  font-size: medium;
  margin: 0 0 0.98rem;
  padding: 0 1.25rem;
  position: absolute;
  top: 3.75rem;
  transform: translateY(3px);
  transition: 0.3s;
  opacity: 0;
}

.project_card:hover .project_button {
  opacity: 1;
}

.project_card:hover .project_title,
.project_card:hover .project_category {
  opacity: 1;
  transform: translateY(0);
}
.arrow-left,
.arrow-right {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d3a612;
  border-radius: 0.65rem;
  border: 1.5px solid #d3a612;
  background: #f2f2f2;
  position: absolute;
  top: 55%;
  z-index: 2;
  cursor: pointer;
}

.arrow-left span,
.arrow-right span {
  font-size: 2rem;
  font-weight: 500;
}

.project_card {
  display: block;
  margin-left: 70px;
  background: #0096df;
  border-radius: 0.65rem;
  border: 1.5px solid #ffffff;
  opacity: 1;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  box-shadow: inset;
  width: 500px;
  height: 500x;
}
.project_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project_card,
.project_card * {
  padding: 0;
}
@media screen and (max-width: 1050px) {
  .project_card {
    width: 100%;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    box-sizing: border-box;
  }
}
