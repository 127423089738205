.contact-form-content {
  width: 100%;
}
.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal.show {
  display: block;
}


form {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
}

.name-container {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
}

form input,
form textarea {
  flex: 1;
  width: 100%;
  font-size: 0.9rem;
  color: #fff;
  background: #169ee8;
  border-radius: 0.6rem;
  border: 1.5px solid #ffffff;
  padding: 1rem;
}

form textarea {
  resize: none;
}

form button {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  border: 1.5px solid #ffffff;
  border-radius: 0.6rem;
  background: #d3a612;
  padding: 0.7rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

form button:hover {
  color: #e2cd0f;
  background: transparent;
}

@media (max-width: 450px) {
  .name-container {
    flex-direction: column;
    align-items: flex-start;
  }

  form input,
  form textarea {
    padding: 0.7rem;
    border-radius: 0.3rem;
  }

  form button {
    font-size: 0.9rem;
    padding: 0.7rem;
    border-radius: 0.3rem;
  }
}
::placeholder {
  color: #ffffff;
}
