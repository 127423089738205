.hero-container {
  display: flex;
  align-items: center;
  position: relative;
}

.hero-container::after,
.hero-container::before {
  content: " ";
  width: 28.125rem;
  height: 28.125rem;
  border-radius: 28.125rem;
  background: #ffffff;
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.hero-container::after {
  top: -3rem;
  left: -5rem;
}
.hero-container::before {
  bottom: 2rem;
  right: 0rem;
}

.hero-content {
  flex: 1;
}

.hero-content h2 {
  position: relative;
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 5rem;
  margin-bottom: 1rem;
  color: #00a9fd9f;
  text-shadow: 0.025em 0.025em 0 rgb(254, 254, 254),
    0.05em 0.05em 0 rgb(238, 238, 238), 0.075em 0.075em 0 rgb(240, 240, 240);
}
.cursorStyle {
  color: red;
}
.hero-content p {
  width: 80%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
}
.leftside {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 430px;
}

.hero-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 5rem;
}
.leftside,
.profilepicture {
  display: flex;
  flex-direction: column; /* Aligner les enfants en ligne (horizontal) */
  align-items: center;
}

.hero-img > div {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.profilepicture img {
  width: 500px;
  height: 400px;
  object-fit: cover;
}

.hero-img > div > img {
  width: 25rem;
  transition: all 0.3s ease;
}
.bottomside {
  margin-right: 10px;
}
.tech-icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.65rem;
  border: 1.5px solid #ffffff;
  position: relative;
}

.tech-icon img {
  width: 3rem;
  height: auto;
  transition: all 0.3s ease;
}

.hero-img > div > img:hover,
.tech-icon img:hover {
  transform: translateY(-0.5rem);
}

@media (max-width: 1024px) {
  .hero-content h2 {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 4rem;
  }

  .hero-content p {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
  .hero-img > div > img {
    width: 20rem;
  }

  .tech-icon {
    width: 4rem;
    height: 4rem;
  }

  .tech-icon img {
    width: 2.5rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-img {
    margin: 2rem 0 2rem 0;
  }

  .hero-container h2 {
    margin-top: 3rem;
  }

  .hero-content h2,
  .hero-content p {
    width: auto;
    text-align: center;
  }
  .hero-content h2 {
    font-size: 15px;
    line-height: 2.7rem;
  }
  .profilepicture img {
    width: 600px;
    height: 420px;
    margin-right: 30px;
  }

  .hero-container::after,
  .hero-container::before {
    content: " ";
    width: 18rem;
    height: 18rem;
  }

  .hero-container::after {
    top: 0rem;
    left: 0rem;
  }
  .hero-container::before {
    bottom: 2rem;
    right: 0rem;
  }
}

@media (max-width: 600px) {
  .hero-content h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .hero-content p {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }

  .hero-img {
    gap: 1rem;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 16rem;
  }

  .tech-icon {
    width: 3.3rem;
    height: 3.3rem;
  }

  .tech-icon img {
    width: 2rem;
  }
}

@media (max-width: 509px) {
  .hero-content h2 {
    font-size: 15px;
    line-height: 2.7rem;
  }
  .profilepicture img {
    width: 600px;
    height: 320px;
    object-fit: cover;
  }

  .hero-content p {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }

  .hero-img {
    gap: 1rem;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 14rem;
  }

  .tech-icon {
    width: 3.1rem;
    height: 3.1rem;
  }

  .tech-icon img {
    width: 1.8rem;
  }
  .leftside {
    left: 12px;
  }
}
@media (min-width: 760px) and (max-width: 955px) {
  .leftside {
    left: 350px;
  }
  .hero-content h2 {
    font-size: 30px;
  }
}
