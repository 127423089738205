.contact-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0096df;
  border-radius: 0.65rem;
  border: 1.5px solid #ffffff;
  padding: 0.1em;
  margin: 6px;
}

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #0096df;
  margin-bottom: 0.8rem;
}
.icon img {
  width: 3rem;
  height: 90px;
  object-fit: contain;
  margin-top: 15px;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 400px) {
  .contact-details-card p {
    font-size: 0.8rem;
  }
}
